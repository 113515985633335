import icon1 from '../../src/assets/images/launch1.png';
import icon2 from '../../src/assets/images/performance 1.png';
import icon3 from '../../src/assets/images/pulse 1.png';
import icon4 from '../../src/assets/images/growth 1.png';
import icon5 from '../../src/assets/images/customer-satisfaction (1) 1.png';
import icon6 from '../../src/assets/images/mindset 1.png';
import icon7 from '../../src/assets/images/team.png';
import icon8 from '../../src/assets/images/waste.png';
import icon9 from '../../src/assets/images/technical-support.png';

export const addedValue = [
    [
        {
            header: 'Go Faster Time-To-Market',
            description: 'Deliver (digital) products or services in weeks not months',
            icon: icon1
        },
        {
            header: 'Maintain High performance',
            description: 'Adopt cross collaboration & communication to work better.',
            icon: icon2
        },
        {
            header: 'Maintain Team Health',
            description: 'Create a sustainable ways of working for Team Health',
            icon: icon3
        }
    ],
    [
        {
            header: 'Scale Easily',
            description: 'Scale your organisation with diverse scaling frameworks',
            icon: icon4
        },
        {
            header: 'Maintain Customer Satisfaction',
            description: 'Collaborate with your customers to build what they love.',
            icon: icon5
        },
        {
            header: 'Shift Mindset',
            description: 'Cultivate the right culture with Leadership and Team Agility',
            icon: icon6
        }
    ],
    [
        {
            header: 'Collaborate Better',
            description: 'Enable transparency of working for Team work & alignment',
            icon: icon7
        },
        {
            header: 'Reduce Waste',
            description: 'Adopt continuous improvement techniques to ensure Value',
            icon: icon8
        },
        {
            header: 'Enjoy Tailored solutions',
            description: 'Adopt agile and lean practices rules and principles tailored to your needs',
            icon: icon9
        }
    ]    
]