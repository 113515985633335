import React, { useState } from 'react';
import './talktous.css';
import Notify from '../notify/Notify';
// import emailjs from '@emailjs/browser';
import emailjs from '@emailjs/browser'


export default function TalktoUs(props){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessLink, setBusinessLink] = useState('');
    const [socialLink, setSocialLink] = useState('');
    const [aboutProject, setAboutProject] = useState('');
    const [projectNeed, setProjectNeed] = useState('');
    const [aboutChallenge, setAboutChallenge] = useState('');
    const [timeSlot, setTimeSlot] = useState('');
    const [btn_text, setBtnText] = useState("Book 1-2-1 Now");
    const [sendSocialStatus, setSendSocialStatus] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('null');
    
    
    const closeBtn = ()=>{
        props.setShowTalk(false);
    }

    const sendTalkToUsNow = (e) => {
        e.preventDefault()
        const templateParams = {

            fullName: name,
            email: email,
            businessName: businessName,
            socialsLink: socialLink,
            businessLink: businessLink,
            aboutProject: aboutProject,
            aboutChallenge: aboutChallenge,
            projectNeed: projectNeed,
            timeSlot: timeSlot
        };

        if (
            templateParams.fullName===''||
            templateParams.email===''||
            templateParams.businessName===''||
            templateParams.socialsLink===''||
            templateParams.businessLink===''||
            templateParams.aboutProject===''||
            templateParams.aboutChallenge===''||
            templateParams.projectNeed===''||
            templateParams.timeSlot==='' ||
            templateParams.email.indexOf('@') < 0
            ) {
            console.log('filed empty');
            setSendSocialStatus(true)
        }else {
            setBtnText('submitting')
            // Send email using EmailJS
            emailjs.send('service_9bg0vbb', 'template_lcxr9yt', templateParams, 'LuSD6HZyXYFZTkUZ5')
            .then((response) => {
                console.log('Email sent successfully:', response.status, response.text);
                props.setShowNotify(true);
                closeBtn();
                window.scrollTo(0, 0);
            }, (error) => {
                console.error('Email sending failed:', error);
            });
        }

        
    };


    return (
        <div className={ props.showTalk ? "talkContainer show" : "talkContainer hide"}>
            <div className="talkContainer-bg" onClick={closeBtn}></div>            
            <div className="talkCard">
                <div className="closeBtn" onClick={closeBtn}>X<i className="fa fa-times"></i></div>
                <div className="talkHead">1-2-1 Coaching Session</div>
                <div className="talkText">
                    <p>Book FREE 15 minutes Agile Coaching session.</p>
                    <br></br>
                    <p>Explore with us how best to develop or deliver your Services, 
                        Products or Projects and to uncover 
                        possible solutions to your product, service or projects key challenges.
                    </p>
                </div>
                <div className="txthead">
                    <span>Strategy</span> Session
                </div>
                <form className="talkInputs">
                    <input 
                        type="text" 
                        placeholder="Enter full name" 
                        value={name}
                        aria-label="name"
                        onChange={(e)=>setName(e.target.value)}
                    />
                    <input 
                        type="email" 
                        placeholder="Enter Valid Email Address"
                        value={email}
                        aria-label="email"
                        onChange={(e)=>setEmail(e.target.value)} 
                    />
                    <input 
                        type="text" 
                        placeholder="Enter Business Name" 
                        value={businessName}
                        aria-label="business-name"
                        onChange={(e)=>setBusinessName(e.target.value)}
                    />
                    <input 
                        type="url" 
                        placeholder="Enter Business website link" 
                        value={businessLink}
                        aria-label="business-link"
                        onChange={(e)=>setBusinessLink(e.target.value)}
                    />
                    <input 
                        type="url" 
                        placeholder="Enter Socials link" 
                        value={socialLink}
                        aria-label="social-link"
                        onChange={(e)=>setSocialLink(e.target.value)}
                    />
                    <select
                        value={projectNeed}
                        onChange={(e)=>setProjectNeed(e.target.value)}
                    >
                        <option value="">Select Coaching Need</option>
                        <option value="Product Challenges">Product Development / Delivery Challenges</option>
                        <option value="Project CHallenges">Project Development / Delivery Challenges</option>
                        <option value="Service CHallenges">Service Development / Delivery Challenges</option>
                    </select>
                    <textarea 
                        placeholder="Tells us about your project" 
                        value={aboutProject}
                        onChange={(e)=>setAboutProject(e.target.value)}
                    />
                    <textarea 
                        placeholder="Tells us about your key challenges and goals" 
                        value={aboutChallenge}
                        onChange={(e)=>setAboutChallenge(e.target.value)}
                    />
                    <select
                        value={timeSlot}
                        onChange={(e)=>setTimeSlot(e.target.value)}
                    >
                        <option value="">Select Time Slot</option>
                        <option value="Fridays 13:00 -- 13:15">Fridays 13:00 -- 13:15</option>
                        <option value="Fridays 13:15 -- 13:30">Fridays 13:15 -- 13:30</option>
                        <option value="Fridays 13:30 -- 13:45">Fridays 13:30 -- 13:45</option>
                        <option value="Fridays 13:45 -- 14:00">Fridays 13:45 -- 14:00</option>
                    </select>
                    <div className="talkBtn">
                            {sendSocialStatus?
                                <p style={{ color:'red', paddingTop:'1rem'}}>Submit failed : All fields are required</p>
                            :''}
                    </div>
                    <div className="talkBtn">
                        <button className='improve-erep' onClick={sendTalkToUsNow}>{btn_text} <i className='fa fa-rocket'></i></button>
                    </div>
                </form>
            </div>
        </div>
    );
}