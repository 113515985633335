import React, {useEffect, useState} from 'react';
import './notify.css'

function Notify({notifyVisibility, showNotify}) {
    //const [showNotify, setShowNotify] = useState(false);
    const toggleForm = () => {
        notifyVisibility(false);
      };

    

      useEffect(()=>{
        console.log('notify reach');
      });

    return ( 
        <div className={showNotify ? 'show' : 'hide'}>
            <div className="additional-section" id="content-idea">
                <div className="content-ideas-form" id="notify">
                <h1 style={{ color:'black'}}>THANK YOU !!</h1>
                <p style={{ color:'#111', fontWeight:'bold', fontSize:'1rem',margin:'1rem auto'}}>Forms submitted successfully, We will get back to you within 72hrs</p>
                <p class="close-button1" onClick={toggleForm}>X</p>
                {/* onClick={toggleForm} */}
                </div>
            </div>
        </div>
        
       
     );
}

export default Notify;