import React, { useState, useRef } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import "./Home.css";
import businessLogo from '../../assets/images/MaskGroup.png';
import busBack from '../../assets/images/imageBg1.svg';
import ring from '../../assets/images/Ellipse 13.svg';
import coachingIcon from '../../assets/images/coaching 1.svg';
import consultantIcon from '../../assets/images/consultant 1.png';
import deliveryIcon from '../../assets/images/development 1.png';
import ring2 from '../../assets/images/Ellipse 21.png';
import ring3 from '../../assets/images/Ellipse 22.png';
import wgaring from '../../assets/images/Ellipse 16.png'
import accCert from '../../assets/images/icp-acc 1.png';
import catCert from '../../assets/images/Layer 2.png';
import atcCert from '../../assets/images/icp-atf 1.png';
import busCert from '../../assets/images/Professional-Level-1 1.png';
import essCert from '../../assets/images/xcertified-less-basics.png.pagespeed.ic 1.png';
import bean from '../../assets/images/bean.svg';
import hrmcLogo from '../../assets/images/HMRC-logo.png';
import misguidedLogo from '../../assets/images/missguided.png';
import digitalbanana from '../../assets/images/svgexport.png';
import wga from '../../assets/images/wga.png';
import quoteIcon from '../../assets/images/quote.svg';
import { addedValue } from "../../config/addedValueData";
import { keyFacts } from "../../config/keyFactsData";



export const Home = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliders, setSliders] = useState(addedValue);
  const lastIndex = sliders.length - 1;

  const form = useRef();


  const handleShowTalk = ()=>{
    props.setShowTalk(true);
  }

  const handleShowXawow = () => {
    props.setShowXawow(true);
  }



  const getTransition = (index) => {
    if (index > currentIndex) {
      return "next-slide";
    }
    else if (index < currentIndex){
      return "previous-slide";
    }
    else {
      return "";
    }
  }

    const nextSlide = () => {
      const nextBtn = document.getElementById('next-btn')
      const prevBtn = document.getElementById('prev-btn')
      if(currentIndex === lastIndex){
        // setCurrentIndex(0);
        nextBtn.disabled = true
      }
      else if (currentIndex < lastIndex){
        prevBtn.disabled = false
        setCurrentIndex((prevIndex) => prevIndex + 1)
        if(currentIndex === lastIndex){
          // setCurrentIndex(0);
          nextBtn.disabled = true
        }
      }
  };

  const prevSlide = () => {
    const prevBtn = document.getElementById('prev-btn')
    const nextBtn = document.getElementById('next-btn')
    if(currentIndex === 0){
      prevBtn.disabled = true;
    }
    else if (currentIndex > 0){
      nextBtn.disabled = false;
      setCurrentIndex((prevIndex) => prevIndex - 1)
      if(currentIndex === 0){
        prevBtn.disabled = true;
      }
    }
  };



  return (
    <div className="home-wrapper">
      <section className="hero-content">
        <div className="content-left">
          <div className="heading">
            <strong>Transform, Start, Scale </strong>
            <br />
            <span>
              and Accelerate your business easily with 
              <br /> Xcelsz Agile Solutions.
            </span>
            <span className="underline"></span>
          </div>
          <div className="top-details">
            <p>
              All in one Agile Solution for your Leaders, Teams of any size, and entire 
              organisation any where.
            </p>
            <p>
              Xcelsz Agile Solution (XAS) is everything you need to Uncover ways to work better 
              to grow your StartUp or SME business, Create a sustainable environment where 
              leadership, Teams and your organisation as a whole can Thrive and successfully 
              deliver projects, products or services that meets your customers expectation.
            </p>
          </div>
        </div>
        <div className="hero-content-right">
          <img
            className="three-people-using-laptop-img"
            src={busBack}
            alt="three-people-using-laptop-img"
          />
          <img
            className="three-people-using-laptop-img"
            src={businessLogo}
            alt="three-people-using-laptop-img"
          />
        </div>

        
      </section>

      {/* <div className="check-business-readiness">Check Business Readiness</div> */}

      <section className="service-content-wrapper">
        <div className="service-container">
          <div className="section-heading">
            <p>
              <span className="what-we-do-bold">What We Do</span> <br />
              <span className="business-underlinef">
                {" "}
                For Your
              </span>{" "}{"  "}
              <span className="business-yellow">{" "}Business</span>
            </p>
          </div>

          <div className="section-content">
            <div className="carousel-container">
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={coachingIcon}
                      alt="Agile coaching icon"
                    />
                  </div>
                  <div>
                    <h2>Agile Coaching</h2>
                    <p>
                      Help you Understand your Agile Organisation and easily empower 
                      your entire organisation for maximum Growth easily.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={consultantIcon}
                      alt="Agile Consultancy Icon"
                    />
                  </div>
                  <div>
                    <h2>Agile Consultancy</h2>
                    <p>
                      Empower you to rapidly expand your business capabilities and swiftly 
                      create products or services that your customers love.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={deliveryIcon}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <h2>Agile Delivery</h2>
                    <p>
                      Facilitate faster project delivery and assist in increasing your 
                      team's capacity while advancing internal capabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="ring-img"
          src={ring}
          alt="ring"
        />
      </section>
      <section className="wga-content-wrapper">
        <div className="wga-container">
          <div className="wga-content-left">
            <div>
              <img
                src={wga}
                alt="Why Go Agile Image"
              />
            </div>
          </div>

          <img
            className="ring3"
            src={wgaring}
            alt="ring"
          />

          <img className="bean" src={bean} alt="bean" />

          <div className="wga-content-right">
            <div className="section-heading">
              <div>
                <strong>Why Go Agile?</strong>
                <span className="underline"></span>
              </div>
            </div>
            <div className="wga-description-para">
              <p>
                In today's digital age, businesses of all sizes must lead the charge 
                in adapting to change swiftly, delivering customer value promptly to 
                guarantee satisfaction, minimizing costs, enhancing the overall success 
                of their (digital) products or services, and upholding sustainable work 
                practices that motivate their Teams, Leadership, and the entire Business 
                to consistently provide customer and business value.
              </p>
              <p>
                Adopting Agile ways of work (AWoW) can amplify commercial performance by 277% 
                and expedite time to market twice as fast as previous methods.{" "}
              {" "}
              </p>
            </div>
            <div className="explore-awow">
              <button type="button" onClick={handleShowXawow}>Book free “Explore AWoW” Session</button>
            </div>
          </div>
        </div>
      </section>
      
      <section className="re-content-wrapper">
        <div className="re-container">
          <div className="section-heading">
            <div className="heading-box">
              <strong>Our Recent Engagements</strong>
              <br />
              <span className="by-our-experts">By Our Experts</span>
            </div>
            <span className="underline"></span>
          </div>

          <div className="section-content">
            <div className="carousel-container">
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={hrmcLogo}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <h2>HMRC CDIO</h2>
                    <p>Agile Consultancy</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="img-box missguided">
                    <img
                      src={misguidedLogo}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <h2>Missguided UK</h2>
                    <p>Agile Consultancy</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={digitalbanana}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <h2>
                      Digital Bananas
                      <br />
                      Technology
                    </h2>
                    <p>Agile Coaching</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="ring-img"
          src={ring2}
          alt="ring"
        />
      </section>
      <section className="xas-content-wrapper">
        <div className="xas-container">
          <div className="xas-content-left">
            <div className="section-heading">
              <div>
                <strong>Why XAS?</strong>
                <span className="underline"></span>
              </div>
            </div>
            <div className="xas-description-para">
              <p>
                We are committed to making Agile coaching, consultancy and 
                delivery accessible to organisations of any size.
              </p>
              <p>
                Embark on an innovative and creative journey of continuous improvement 
                designed for Startups, Micro-enterprises, and SME businesses. Benefit from 
                unparalleled expertise in transitioning to and transforming with Agile ways 
                of working, ensuring a seamless and effective evolution.       
              </p>
              <p>
                <span>
                  Choose XAS as your one-stop Agile Solutions Partner, so that you can 
                  be enabled to say <span>YES to value faster</span> too, just like with our recent engagements.
                </span>  
              </p>
            </div>
          </div>
          <div className="xas-content-right">
            <div className="xas-dots">
              <div className="xas-certs-cards-container">
                <div className="xas-cert-card">
                  <img src={catCert} alt="" />
                </div>
                <div className="xas-cert-card">
                  <img src={accCert} alt="" />
                </div>
                <div className="xas-cert-card">
                  <img src={atcCert} alt="" />
                </div>
                <div className="xas-cert-card">
                  <img src={busCert} alt="" />
                </div>
                <div className="xas-cert-card">
                  <img src={essCert} alt="" />
                </div>
              </div>
              
              <div className="xas-cert-desc">
                <p>Expert Team of Certified Professionals</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gvf-content-wrapper">
        <div className="gvf-container">
          <div className="section-heading">
            <div>
              <strong>The Added Value</strong>
              <br />
              <span>See What Agile Businesss Experience</span>
              <span className="underline"></span>
            </div>
          </div>
          <div className="section-content1">
            <div className="carousel-buttons">
              <button id='prev-btn' onClick={prevSlide}>&lt;</button>
              <button id="next-btn" onClick={nextSlide}>&gt;</button>
            </div>
            <div className="card-design ad-value-slide-container">
              <div className="added-value-slider">
                {
                  sliders.map((slider, i) => {
                    const transitionClass = getTransition(i);
                    // const newSlideIndex = i * 3;
                    return (
                        <div className={`slider ${transitionClass} ${i === currentIndex ? "activee" : ""}` } key={i}>
                          {
                            slider.map((slide, j) => {
                              return (
                                <div className="slide-card" key={j}>
                                  <div className="slide-title">{slide.header}</div>
                                  <div className="slide-img">
                                    <img
                                      src={slide.icon}
                                      alt="slide icon"
                                    />
                                  </div>
                                  <div className="slide-desc">
                                    {slide.description}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <img
          className="ring-img"
          src={ring3}
          alt="ring"
        />
      </section>
      <section className="testimonials-content-wrapper">
        <div className="testimonials-container">
        <div className="section-heading">
            <div>
              <strong>
                Say <span className="yes-yellow">YES</span> To
              </strong>
              <br />
              <span>Getting Value Faster</span>
              <span className="underline"></span>
            </div>
          </div>
          <div className="section-content">
            <div className="carousel-container">
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={quoteIcon}
                      alt="quote"
                    />
                  </div>
                  <div>
                    <p>
                      XAS clients uncover ways to work better and better 
                      each time no matter their location, improving team 
                      health and morale 10x.
                    </p>
                    <div className="testimo-user">
                      <span>-</span>
                      Agile Coaching
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={quoteIcon}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <p>
                      XAS clients save the hassle of looking for the 
                      right delivery team fit, saving time and money 10X.
                    </p>
                    <div className="testimo-user">
                      <span>-</span>
                      Agile Consultancy
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="img-box">
                    <img
                      src={quoteIcon}
                      alt="A meaningful description"
                    />
                  </div>
                  <div>
                    <p>
                      XAS clients improve their go-to market speed by at 
                      least 2X, sustaining a high-performance culture.{" "}
                    </p>
                    <div className="testimo-user">
                      <span>-</span>
                      Agile Consultancy
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kf-content-wrapper">
        <div className="kf-container">
          <div className="section-heading">
            <div>
              <strong>Key Facts</strong>
              <br />
              <span>And </span>
              <span
                style={{
                  borderBottom: "6px solid #FAC710",
                  paddingBottom: "4px",
                }}
              >
                Figures
              </span>
            </div>
          </div>

          <div className="section-content">
            {/* <div className="ctrls-indicator">
              <img src="../../assets/images/control.png" alt="A meaningful description"/>
            </div> */}
            {/* <div className="carousel-buttons">
              <button id='swp-prev-btn' className="swiper-btn-next" >&lt;</button>
              <button id="swp-next-btn" className="swiper-btn-prev">&gt;</button>
            </div> */}
            <div className="carousel-container mobile-view">
            {     
                  keyFacts.map((slide, i) => {
                    const transitionClass = getTransition(i);
                    // const newSlideIndex = i * 3;
                    return (
                      <div className="card">
                          <div className="card-content">
                          <div>
                            <h2>{slide.number}</h2>
                            <p style={{ fontWeight: "470" }}>{slide.description}</p>
                          </div>
                          </div>
                      </div>
                     
                    )
                  })
                }
            </div>
              

            <Swiper 
              slidesPerView={4}
              spaceBetween={30}
              navigation={true}
              modules={[Navigation]}
              className="carousel-container"
            >
              {
                keyFacts.map((keyFact, i) => {
                  return (
                    <SwiperSlide className="card" key={i}>
                      <div className="card-content">
                        <div>
                          <h2>{keyFact.number}</h2>
                          <p style={{ fontWeight: "470" }}>{keyFact.description}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
        <img
          className="ring-img"
          src={ring2}
          alt="ring"
        />
      </section>

      <section className="find-out-more">
        <h3>
          Want to Find out More About
          <br />
          <span className="agile-ways">Agile Ways of Working (AWoW)?</span>
        </h3>
        <div className="last-cta">
          <button  onClick={handleShowTalk} className="talk-to-us">Book 1-2-1 Session</button>
          {/* <button className="check-agile-mindset">Check Agile Mindset</button> */}
        </div>        
      </section>
      

      <section className="footer-content-wrapper"></section>
    </div>
  );
};
