import "./Footer.css";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import copyright from '../../assets/images/copyright.png';
import telegram from '../../assets/images/telegram.png'



export const Footer = (props) => {
  const [subConfirm, setSubConfirm] = useState();
  const [emailValid, setEmailValid] = useState();
  const userEmail = useRef();

  const handleShowTalk = ()=>{
    props.setShowTalk(true);
  }

  const handleShowXawow = () => {
    props.setShowXawow(true);
  }

  const clearEmailField = () => {
      userEmail.current.value = "";
    }

  const closeSubConfirm = (e) =>{
    e.preventDefault();
    console.log("Subscription confirm closed");
    setSubConfirm();
  }

   
  const ValidateEmail = (input) => {

    const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
    const emailtxt = document.querySelector("#emailtxt");
    const error = document.querySelector(".error");

    if (input === "" || input.match(validEmail)) {
        error.style.display = "none";
        emailtxt.style.outline = "revert-layer";
        setEmailValid(1);
        // return true;
    } else {

      console.log("Invalid email address!");
      error.style.display = "block"
      emailtxt.style.outline = "2px solid red";
      setEmailValid(0);
    //   return false;

    }

  }

  const form = useRef();

  const submitSubscription = (e)=>{
    e.preventDefault();

    if (emailValid){

      emailjs.sendForm('service_lrekcyz', 'template_0sgyoak', form.current, 'cm_1OMdDgstBwtAkp')
      .then((result) => {
          console.log('sent');
          setSubConfirm(true);
          clearEmailField();

          // disappearing message
          window.setTimeout(
        
            function removethis()
            {
                setSubConfirm(false)
            }, 10000);   

      }, (error) => {
          console.log(error);
          console.log('not sent');
      });

    }else{
        console.log("Invalid email")
    }

  }

  return (
    <div>
      <section className="footer-content-wrapper">
        <div className="footer-container">
          <div className="footer-logo">
            {/* <img src= alt="xas logo" /> */}
            <a href="/"><button className="ft-logo">XAS</button></a>
            <h2>Xcelsz Agile Solutions</h2>
          </div>
          <div className="footer-link-bars">
            <div className="footer-bar-left">
              <p>
                {/* Helping Businesses of any size to
                <br /> maximise measurable Value Faster */}
                  We work with businesses of all sizes as their outsourced Agile Coaching, 
                  Consultancy, and Delivery Partner to assist them in maximizing and sustaining 
                  measurable value faster with Africa's top Agile talents. 
              </p>
              <p className="address">
                Registered Address AT-1130-0938 (GH) | CM20 1YS (UK)
              </p>
            </div>
            <div className="footer-bar">
              <div className="footer-links">
                <h2>Get Started</h2>
                <ul>
                  <a href="/go-agile">
                    <li>Go Agile </li>
                  </a>
                  
                  {/* <li>Leadership Style check</li>
                  <li>Agile Mindset Check</li>
                  <li>Team Readiness Check</li> */}
                  {/* <li className="business-readiness-check-mobile">Business Readiness Check</li> */}
                </ul>
              </div>
              <div className="footer-links">
                <h2>Support</h2>
                <ul>
                  <li onClick={handleShowXawow}>Explore AWoW</li>
                  <li onClick={handleShowTalk}>1-2-1 Session{" "}</li>
                </ul>
              </div>
            </div>
            
          </div>
          <div className="footer-email-subz">
            <div className="foot-text">
              <p>Subscribe to news letter</p>
            </div>
            <div className="footer-nls emailField">
              {
                subConfirm &&
                <div className="confirm-subcription">
                  <p>
                    Your subscription has been confirmed. You’ve been added to 
                    our list and will hear from us soon. Thank YOU
                  </p>
                </div>
              }
              <form className="form-group email-input" ref={form} onSubmit={submitSubscription}>
                <input className='email-subscription' type="email" id="emailtxt" name="user_email" placeholder="ENTER YOUR EMAIL" ref={userEmail} onKeyUp={(e)=>ValidateEmail(e.target.value)} required/>
                <input type="submit" className="btn" value="SEND"/>
                {/* <button className="btn">SEND</button>
                <a 
                  href="mailto:admin@xcelsz.com?subject=XAS%20Newsletter 
                  Subscription&body=I%wishtosubscribetoyourweeklyNewsLetter"
                  target="__blank"
                >                  
                </a> */}
              </form>
              <span className="error">please enter a valid email: example@domain.com</span>
            </div>
          </div>
          <div className="footer-social-links">
            <div className="solution">
              <div className="xds-team">
                <p>
                  This is created with VIM 💪🏿 by
                </p>
                <a href="http://xpsdemo.xcelsz.com/" target="__blank">
                  <div className="xds-logo">
                    <div>XDS</div>
                  </div>
                </a>
                <span>Team</span>
              </div>
            </div>
            
            <div className="social-link-left">
              <p>Follow us </p>
              <a href="https://t.me/XcelszAgile" target="__blank">
                <img
                  src={telegram}
                  alt="Telegram Image"
                />
              </a>
              

              {/* <img src="../../assets/images/twitter.png" alt="Telegram Image"
              style={{width:"2rem", cursor:"pointer"}} />

              <img
                src="../../assets/images/instagram.png"
                alt="Telegram Image"
                style={{width:"2rem", cursor:"pointer"}} 
              />

              <img
                src="../../assets/images/facebook.png"
                alt="Telegram Image"
                style={{width:"2rem", cursor:"pointer"}}
              />

              <img
                src="../../assets/images/linkedin.png"
                alt="Telegram Image"
                style={{width:"2rem", cursor:"pointer"}}
              /> */}
            </div>

            <div className="copyright-field">
              <img
                src={copyright}
                alt="A meaningful description"
              />
              <span>{ new Date().getFullYear() }</span>
              <a href="http://xcelsz.com/" target="__blank">
                <p>xcelsz.com</p>
              </a>              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
