import React, {useState, useEffect} from 'react';
import {
  createBrowserRouter,
  Routes,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Home } from "./pages/home/Home";
import Navbar from "./components/navbar/Navbar";
import ErrorBoundary from "./components/errorHandler/ErrorBoundary";
import "./App.css";
import { Footer } from "./components/footer/Footer";
import GoAgile from "./pages/goAgile/GoAgile";
import { Cookies } from "./components/cookies/cookies";
import TalktoUs from "./components/talk/talktous";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Notify from './components/notifyTalk/Notify';
import ExploreXawow from './components/exploreXawow/exploreXawow';
import GoogleAnalytics from './Google_Analytics';
import avatar from './assets/images/xaslogo.png';

function App() {
  const [showTalk, setShowTalk] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [showNotify, setNotify] = useState(false);
  const [showXawow, setShowXawow] = useState(false);

  useEffect(()=>{
    let notification
    let interval
    document.addEventListener("visibilitychange",()=>{
      if(document.visibilityState === 'hidden'){
        interval = setInterval(() => {
          Notification.requestPermission().then(param => {
            if(param === 'granted'){
               notification = new Notification('Xcelsz Agile Solutions', {
                body: "Check Out how you can create a robust online presence for your business.",
                icon: avatar,
                tag: 'XAS'
              })
      
              notification.addEventListener("click", ()=>{
                window.location.href = '';
              })
            }
          }) 
        }, 50000)
      }else{
        if(interval) clearInterval(interval)
      }
    })
  },[])

  const Layout = () => {
    return (
      <div className="app">
        <GoogleAnalytics trackingID="G-9JY889BW49"/>
        <TalktoUs 
          showTalk={showTalk}
          setShowTalk={setShowTalk}
          setShowNotify={setNotify}/>
        <ExploreXawow 
          showXawow={showXawow}
          setShowXawow={setShowXawow}
        />
        <Cookies />
        <Navbar />
        <Notify notifyVisibility={setNotify} showNotify={showNotify}/>
        <Outlet />
        {/* <Home
          showTalk={showTalk}
          setShowTalk={setShowTalk}/> */}

<div className="icons-btn">
          {showIcons && (
            <>
              <FloatingWhatsApp
                accountName="Xcelsz Digital Solutions"
                phoneNumber="+233501338581"
                avatar="https://cdn.chaty.app/images/wa-logo.webp"
                className="whatsapp-icon"
                title="WhatsApp Us"
              />
              <a href="tel:+233243802228" className="call-icon" title="Phone">
                <div className="styles-module_floatingWhatsapp__yWF5K  floating-whatsapp call">
                  <div
                    className="styles-module_whatsappButton__tVits floating-whatsapp-button"
                    aria-hidden="true"
                  >
                    <svg
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="color-element"
                        cx="19.4395"
                        cy="19.4395"
                        r="19.4395"
                        fill="transparent"
                      ></circle>
                      <path
                        d="M19.3929 14.9176C17.752 14.7684 16.2602 14.3209 14.7684 13.7242C14.0226 13.4259 13.1275 13.7242 12.8292 14.4701L11.7849 16.2602C8.65222 14.6193 6.11623 11.9341 4.47529 8.95057L6.41458 7.90634C7.16046 7.60799 7.45881 6.71293 7.16046 5.96705C6.56375 4.47529 6.11623 2.83435 5.96705 1.34259C5.96705 0.596704 5.22117 0 4.47529 0H0.745882C0.298353 0 5.69062e-07 0.298352 5.69062e-07 0.745881C5.69062e-07 3.72941 0.596704 6.71293 1.93929 9.3981C3.87858 13.575 7.30964 16.8569 11.3374 18.7962C14.0226 20.1388 17.0061 20.7355 19.9896 20.7355C20.4371 20.7355 20.7355 20.4371 20.7355 19.9896V16.4094C20.7355 15.5143 20.1388 14.9176 19.3929 14.9176Z"
                        transform="translate(9.07179 9.07178)"
                        fill="#111"
                      ></path>
                    </svg>
                  </div>
                </div>
              </a>
              <a
                href="mailto:info@xcelsz.com?subject=Talk to us"
                className="email-icon"
                title="Email"
              >
                <div className="styles-module_floatingWhatsapp__yWF5K  floating-whatsapp email">
                  <div
                    className="styles-module_whatsappButton__tVits floating-whatsapp-button"
                    aria-hidden="true"
                  >
                    <svg
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="color-element"
                        cx="19.4395"
                        cy="19.4395"
                        r="19.4395"
                        fill="#FF485F"
                      ></circle>
                      <path
                        d="M20.5379 14.2557H1.36919C0.547677 14.2557 0 13.7373 0 12.9597V1.29597C0 0.518387 0.547677 0 1.36919 0H20.5379C21.3594 0 21.9071 0.518387 21.9071 1.29597V12.9597C21.9071 13.7373 21.3594 14.2557 20.5379 14.2557ZM20.5379 12.9597V13.6077V12.9597ZM1.36919 1.29597V12.9597H20.5379V1.29597H1.36919Z"
                        transform="translate(8.48619 12.3117)"
                        fill="white"
                      ></path>
                      <path
                        d="M10.9659 8.43548C10.829 8.43548 10.692 8.43548 10.5551 8.30588L0.286184 1.17806C0.012346 0.918864 -0.124573 0.530073 0.149265 0.270879C0.423104 0.0116857 0.833862 -0.117911 1.1077 0.141283L10.9659 7.00991L20.8241 0.141283C21.0979 -0.117911 21.5087 0.0116857 21.7825 0.270879C22.0563 0.530073 21.9194 0.918864 21.6456 1.17806L11.3766 8.30588C11.2397 8.43548 11.1028 8.43548 10.9659 8.43548Z"
                        transform="translate(8.47443 12.9478)"
                        fill="white"
                      ></path>
                      <path
                        d="M9.0906 7.13951C8.95368 7.13951 8.81676 7.13951 8.67984 7.00991L0.327768 1.17806C-0.0829894 0.918864 -0.0829899 0.530073 0.190849 0.270879C0.327768 0.0116855 0.738525 -0.117911 1.14928 0.141282L9.50136 5.97314C9.7752 6.23233 9.91212 6.62112 9.63828 6.88032C9.50136 7.00991 9.36444 7.13951 9.0906 7.13951Z"
                        transform="translate(20.6183 18.7799)"
                        fill="white"
                      ></path>
                      <path
                        d="M0.696942 7.13951C0.423104 7.13951 0.286185 7.00991 0.149265 6.88032C-0.124573 6.62112 0.012346 6.23233 0.286185 5.97314L8.63826 0.141282C9.04902 -0.117911 9.45977 0.0116855 9.59669 0.270879C9.87053 0.530073 9.73361 0.918864 9.45977 1.17806L1.1077 7.00991C0.970781 7.13951 0.833862 7.13951 0.696942 7.13951Z"
                        transform="translate(8.47443 18.7799)"
                        fill="white"
                      ></path>
                    </svg>
                  </div>
                </div>
              </a>
            </>
          )}

          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="-496 507.7 54 54"
            style={{ enableBackground: "new -496 507.7 54 54" }}
            xmlSpace="preserve"
            className="open-chaty"
            onClick={() => setShowIcons((prev) => !prev)}
          >
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html: ".csaas-sts1{fill: #111;}.csaas-st0{fill: #ffff;}",
              }}
            />
            <g>
              <circle cx={-469} cy="534.7" r={27} fill="rgb(250, 199, 16)" />
            </g>
            {showIcons ? (
              // When icons are displayed, show "X" icon
              <path
                className="close-collapse"
                d="M-476.9,523.7l20.3,20.3m0,-20.3l-20.3,20.3"
                fill="red"
                stroke="#000000"
                strokeWidth="4"
              />
            ) : (
              // When icons are not displayed, show original icon
              <>
                <path
                  className="csaas-sts1"
                  d="M-459.9,523.7h-20.3c-1.9,0-3.4,1.5-3.4,3.4v15.3c0,1.9,1.5,3.4,3.4,3.4h11.4l5.9,4.9c0.2,0.2,0.3,0.2,0.5,0.2 h0.3c0.3-0.2,0.5-0.5,0.5-0.8v-4.2h1.7c1.9,0,3.4-1.5,3.4-3.4v-15.3C-456.5,525.2-458,523.7-459.9,523.7z"
                />
                <path
                  className="csaas-st0"
                  d="M-477.7,530.5h11.9c0.5,0,0.8,0.4,0.8,0.8l0,0c0,0.5-0.4,0.8-0.8,0.8h-11.9c-0.5,0-0.8-0.4-0.8-0.8l0,0C-478.6,530.8-478.2,530.5-477.7,530.5z"
                />
                <path
                  className="csaas-st0"
                  d="M-477.7,533.5h7.9c0.5,0,0.8,0.4,0.8,0.8l0,0c0,0.5-0.4,0.8-0.8,0.8h-7.9c-0.5,0-0.8-0.4-0.8-0.8l0,0C-478.6,533.9-478.2,533.5-477.7,533.5z"
                />
              </>
            )}
          </svg>
        </div>
        <Footer
          showTalk={showTalk}
          setShowTalk={setShowTalk}
          showXawow={showXawow}
          setShowXawow={setShowXawow}
        />    
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home 
          showTalk={showTalk}
          setShowTalk={setShowTalk}
          setShowNotify={setNotify}
          showXawow={showXawow}
          setShowXawow={setShowXawow}
          />,
        },
      ],
    },
    {
      path: "/go-agile",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <GoAgile />,
        },
      ],
    },
  ]);

  return (
    <ErrorBoundary>
      <RouterProvider router={router}>
        <Routes />
      </RouterProvider>
    </ErrorBoundary>
  );
}

export default App;
