import React from "react";
import './thankyou.css';

function ThankYou(props) {
    

    return (
        <div className={props.showThanks ? "thanks-container show" : "thanks-container"}>
            <div className="thanks">
                <div className="text">
                    <h1 style={{ color:'black'}}>THANK YOU !!</h1>
                    <p style={{ color:'#111', fontWeight:'bold', fontSize:'1rem'}}>Forms submitted successfully, We will get back to you within 72hrs</p>
                </div>
                <button type="button" onClick={()=>props.closeThanks(false)}>X</button>
            </div>
        </div>
    );
}

export default ThankYou;