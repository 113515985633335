import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  return (
    <nav>
      <Link to="/" className="left">
        XAS
      </Link>
      {/* <div className="right"> */}
        {/* <Link to="/go-agile" className={location.pathname === '/go-agile' ? 'ga-text-container' : 'text-container'}>
          <div className="text">Go Agile Now</div>
        </Link> */}
         {location.pathname !== '/go-agile' && (<Link to="/go-agile" className={location.pathname === '/go-agile' ? 'ga-text-container' : 'text-container'}>
          <div className="text">Go Agile Now</div>
        </Link>)}
      {/* </div> */}
   
    </nav>
  );
};

export default Navbar;
