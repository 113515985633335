import React, { useEffect } from 'react';

const GoogleAnalytics = ({ trackingID }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', trackingID);
    };

    // Track page view on component mount (for SPAs)
    window.gtag('config', trackingID, {
      page_path: window.location.pathname
    });

    // Optional: Track additional events or interactions
    // Example: gtag('event', 'click', { event_category: 'Button Click', event_label: 'Submit Button' });

  }, [trackingID]);

  return null;
};

export default GoogleAnalytics;
