import { useEffect, useState } from "react";
import "./cookies.css"


export const Cookies = (params) => {
    const[visibility, setVisibility] = useState('')
    const[preference, setPreference] = useState(true)
    const[statistics, setStatistics] = useState(true)
    const[marketing, setMarketing] = useState(true)
    function acceptCookies() {
        setVisibility('hide')
        storeCookies()
    }

    function storeCookies() {
      localStorage.setItem('cookies',true)
    }

    useEffect(()=>{
      const status = localStorage.getItem('cookies')
      if (status) {
        setVisibility('hide')
      }
    })
    return(
        <div className={`cookies ${visibility}`}>
        <div className="col-lef">
          <div className="detail">
            This website uses cookies to improve users experience, learn about and 
            respond to user website activities, give users a more personalized 
            experience and get feedback to improve your interaction with our website 
            and product and services available You can click on cookie policy to learn 
            more about the different types of cookies being used.
          </div>
          <div className="buttons">
            <div className="li">
              {/* changing all span element to label */}
              <label htmlFor="necessary[]">Necessary</label> 
              <input
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="necessary"
                checked={true}
              />
            </div>
            <div className="li">
              <label htmlFor="necessary[]">Preferences</label>
              <input
                onClick={()=>{setPreference((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="preferences"
                checked={preference}
              />
            </div>
            <div className="li">
              <label htmlFor="necessary[]">Statistics</label>
              <input
                onClick={()=>{setStatistics((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="statistics"
                checked={statistics}
              />
            </div>
            <div className="li">
              <label htmlFor="necessary[]">Marketing</label>
              <input
                onClick={()=>{setMarketing((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="marketing"
                checked={marketing}
              />
            </div>
            <div className="col-ryt" onClick={acceptCookies}>
              {/* changing the anchor tag to p tag */}
                <p>Accept</p>
            </div>
          </div>
        </div>
      </div>
    )
}