export const keyFacts = [
    {
        number: '126+',
        description: 'Hours of coaching conversations'
    },
    {
        number: '336+',
        description: 'Sprints Completed'
    },
    {
        number: '3+',
        description: 'Businesses Served'
    },
    {
        number: '35+',
        description: 'Projects Closed'
    },
    {
        number: '1+ YEAR',
        description: 'Business Agility Institute Member'
    },
    {
        number: '1+ YEAR',
        description: 'Agile Marketing Alliance Member'
    },
    {
        number: '1+ YEAR',
        description: 'Agile Alliance Member'
    },
    {
        number: '1+ YEAR',
        description: 'Agile Business Consortium Member'
    },
    {
        number: '1+ YEAR',
        description: 'UnFix Member'
    },
    {
        number: '1+ YEAR',
        description: 'Transformation Professionals Member'
    }
]