import './exploreXawow.css';
import Calender from 'react-calendar';
import { useState, useRef} from 'react';
import emailjs from '@emailjs/browser';


const ExploreXawow = (props) => {
    const [date, setDate] = useState(new Date());
    const [index, setIndex] = useState(0);
    const [email, setEmail] = useState('');
    const [clientName, setClientName] = useState('');
    const [delegates, setDelegates] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [role, setRole] = useState('');
    const [location, setLocation] = useState('');
    const [industry, setIndustry] = useState('');
    const [xawowFormSent, setXawowFormSent] = useState(false);
    const [bkDayTime, setBkDayTime] = useState('');
    const [avTime, setAvTime] = useState(0);
    
    
    const closeXawow = ()=>{
        props.setShowXawow(false);
    }

    // close with ESC button
    document.onkeydown = (evt) => {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
           isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
           isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
           props.setShowXawow(false);
        }
     };

    const handleTime = () => {
        // const day = date.toDateString()
        // e.target.className = 'active-date';
        const btns = document.querySelectorAll('.av-day')
        for (let i = 0; i < btns.length; i++){
            btns[i].onclick = ()=>{
                btns[i].classList.add('active-date')
                // window.location.href='#av-time'
            }
        }
        setAvTime(1);
        // setBkDayTime(date)
        // console.log(bkDayTime)
    }

    const clearForm = () => {
        setBkDayTime('');
        setLocation('');
        setDelegates('');
        setEmail('');
        setClientName('');
        setBusinessName('');
        setRole('');
        setIndustry('');
    }
    
    const xawowForm = useRef();

    const submitXawowForm = (e)=>{
        e.preventDefault();

        //emailjs.sendForm('service_lrekcyz', 'template_r2bnnhn', xawowForm.current, 'cm_1OMdDgstBwtAkp')
        emailjs.sendForm('service_9bg0vbb', 'template_lcxr9yt', xawowForm.current, 'LuSD6HZyXYFZTkUZ5')
        .then((result) => {
            console.log('sent');
            setXawowFormSent(true);
            clearForm()
            window.scrollTo(0, 0)

            // disappearing message
            window.setTimeout(
            
                function removethis()
                {
                    setXawowFormSent(false)
                }, 10000);   

        }, (error) => {
            console.log(error);
            console.log('not sent');
        });
    }

    const setFormPage = () => {
        
        setBkDayTime(date.toDateString() + ", 12:00PM")
        console.log(bkDayTime)
        if (index === 0){
            setIndex(1);
        } else {
            setIndex(0);
        }
        
    }

    
    return (
        <>
            <div className={`xawow-wrapper ${props.showXawow ? '' : 'xw-hide'}`}>
                <div className='bg-shadow' onClick={closeXawow}></div>
                <div className='xawow-container'>
                    <div className='xw-main'>
                        <div className="xw-closeBtn" onClick={closeXawow}><button onClick={closeXawow}>X</button></div>
                        <h1 className='xw-heading'>Explore XAWoW</h1>
                        <h2 className='xw-desc'>
                            <span>Book a Free</span> Lunch & Learn Session to explore how Xcelsz 
                            Agile ways of working <span>(XAWoW)</span> can add value to your business
                        </h2>
                        {
                            index === 0 &&
                            <div className='xw-bk-container'>
                                <div className='xw-booking'>
                                    <div className='xw-calender'> 
                                        <Calender 
                                            onChange = {setDate}
                                            value = {date}
                                            minDate={new Date()}
                                            onClickDay={handleTime}
                                            className = 'calender'
                                            tileDisabled={({ activeStartDate, date, view }) => view === 'month' && date.getDay() !== 5}
                                            tileClassName={({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 5 ? 'av-day' : '' }
                                                                                    
                                        />
                                    </div>
                                    <div className='xw-time'>
                                        <div className='time-desc'>
                                            AVAILABLE SLOTS <span>Please Select Time To Book</span> 
                                        </div>
                                        {
                                            avTime === 1 &&
                                            <ul>
                                                <li>
                                                    <button className='unav-time' id='' disabled > 01:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 02:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 03:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 04:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 05:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 06:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 07:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 08:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 09:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 10:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 11:00 AM</button>
                                                </li>
                                                <li>
                                                    <button className='av-time' id='av-time' onClick={setFormPage}> 12:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 01:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 02:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 03:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 04:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 05:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 06:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 07:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 08:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 09:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 10:00 PM</button>
                                                </li>
                                                <li>
                                                    <button className='unav-time' id='' disabled> 11:00 PM</button>
                                                </li>
                                            </ul>
                                            
                                        }
                                        
                                    </div>
                                </div>
                                {/* <button className='xw-signup-btn' onClick={setFormPage}>
                                    Sign up for Lunch & Learn Now
                                </button> */}
                            </div>
                        }       
                        {
                            index === 1 &&
                            <div className='xw-bk-container'>
                                <div className='xw-booking xw-form'>
                                    <form ref={xawowForm} onSubmit={submitXawowForm}>
                                        <input type="text" name="xawowBookingDate" value={bkDayTime} />
                                        <select name="numberOfDelegates" id="" required>
                                            <option value="">Select number of delegates</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                        <input type="text" name="clientName" value={clientName} onChange={(e)=>setClientName(e.target.value)} placeholder='Enter Name(s)' required />
                                        <input type="email" name="email" id=""  value={email} onChange={(e)=>setEmail(e.target.value)}  placeholder='Enter Email(s)' required />
                                        <input type="text" name="businessName" id="" value={businessName} onChange={(e)=>setBusinessName(e.target.value)} placeholder='Enter Business Name' required />
                                        <input type="text" name="role" id="" value={role} onChange={(e)=>setRole(e.target.value)} placeholder='Enter Role(s)' required />
                                        <input type="text" name="country" id="" value={location} onChange={(e)=>setLocation(e.target.value)} placeholder='Enter Location' required />
                                        <input type="text" name="industryType" value={industry} onChange={(e)=>setIndustry(e.target.value)} placeholder='Enter Industry Type' required />
                                        <input type="submit" value="Sign up for Lunch & Learn Now" id="" />
                                    </form>   
                                    {/* <div className='xw-confirm'>
                                        Thank you for Signing Up
                                    </div> */}
                                    {
                                        xawowFormSent &&
                                        <div className='xw-confirm'>
                                            Thank you for Signing Up
                                        </div>
                                    }                                
                                </div>
                                <div className='xw-form-btns'>
                                    <button className='xw-form-btn' onClick={setFormPage}>
                                        Go Back
                                    </button>
                                    {/* <button type='submit' className='xw-form-btn' >
                                        Sign up for Lunch & Learn Now
                                    </button> */}
                                </div>
                                
                            </div>
                        }                        
                        
                    </div>                    
                </div>                
            </div>
        </>
    )
};
export default ExploreXawow;