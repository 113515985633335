import React,{useState, useRef} from 'react';
import './goAgile.css'; // Make sure to adjust the path based on the actual location of your CSS file
import illus from "../../assets/Frame.png"
import check from "../../assets/Group520.png"
import FormOverlay from './formOverlay';
import ThankYou from './thankyou';
import GoAgileImg from "../../assets/images/GoAgileImg.png";
import GoAgileSecond from "../../assets/images/GoAgileSecond.png";
import GoAgileLast from "../../assets/images/GoAgileLast.png";
import busBack from '../../assets/images/imageBg1.svg';
import ring from '../../assets/images/Ellipse 16.png';
import bean from '../../assets/images/bean.svg';
import ring2 from '../../assets/images/Ellipse 21.png';
import ring4 from '../../assets/images/ring.svg';
// import $ from 'jquery';

function GoAgile(){
  const ref = useRef();
  const ref3 = useRef();
  const [showForm, setShowForm] = useState(false);
  const [plan, setPlan] = useState('');
  const [showThanks, setShowThanks] = useState(false);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const clickableDiv1 = () => {
    let divP = document.querySelector('.choosenPlan p').innerText;
    setPlan(divP);
    setShowForm(true);
  };

  const clickableDiv2 = () => {
    let divP = document.querySelector('.choosenPlan2 p').innerText;
    setPlan(divP);
    setShowForm(true);
  };

  const clickableDiv3 = () => {
    let divP = document.querySelector('.choosenPlan3 p').innerText;
    setPlan(divP);
    setShowForm(true);
  };


  const [switchCount, setSwitchCount] = useState(0);

  function handleSwitchMinus() {
    if (switchCount === 0) {
      setSwitchCount((old) => 0);
    } else if (switchCount > 0) {
      setSwitchCount((old) => old - 1);
    }
  }

  function handleSwitchAdd() {
    if(switchCount===2){
      setSwitchCount((old) => old = 2);
    }
    else if (switchCount <= 2) {
      console.log(switchCount)
      setSwitchCount((old) =>  {return old = old + 1});
      
    }
   
  }

  return (
    <div className='home-wrapper'>
      <FormOverlay
        showForm={showForm}
        closeForm={setShowForm}
        openThanks={setShowThanks}
        choosenPlan={plan}
      />

      <ThankYou
        showThanks={showThanks}
        closeThanks={setShowThanks}
      />

      <section className="hero-content">
        <div className="content-left">
          <div className="heading">
            <strong>Everything you</strong>
            <br />
            <span>need to advance<br />your business. </span>
            <span className="underline"></span>
          </div>
          <div className="top-details">
            <p>
              XAS facilitates the acceleration of products, services, and startups by 
              leveraging diverse Agile, DevOps, Lean, scaling frameworks, modern leadership, 
              and Business Transformation Principles and Practices.
            </p>
            <p>
              Our approach promotes an incremental improvement mindset aimed at driving sustainable growth and innovation.
            </p>
            <p>
              We base our actions on conducting pilot runs, continuous learning, and continuous 
              improvement to reduce risks, save time, and optimize resources.
            </p>
          </div>
        </div>
        <div className="hero-content-right">
          <img
            className="three-people-using-laptop-img"
            src={busBack}
            alt="three-people-using-laptop-img"
          />
          <img
            className="three-people-using-laptop-img"
            src={GoAgileImg}
            alt="three-people-using-laptop-img"
          />
        </div>
        {/* <img className="three-people-using-laptop-img" src="../assets/images/three-pple.svg" alt="three-people-using-laptop-img" /> */}
      </section>

      <section className='pricing-section'>
        <div className='pricing-container'>
          <div className='pricing-heading'>
            <p>
              <span className='header-bold'>Our</span>
            </p>
            <p className='second-text'>
              <span className='slim-text line'>Pricing </span>
              <span className='slim-text'>Plan</span>
            </p>
            <div className='sub-text'>
              <p>XAS can help you “Go Agile” by helping you to do it yourself through agile coaching, do it for you through agile consultancy or do it with you through delivery.</p>
            </div>
          </div>
        
          <div className='btn-switch'>
            <div className={`${switchCount === 0?'switch-1':'switch-1 active'}`} onClick={handleSwitchMinus}>
              <p> {'<'}</p>
            </div>
            <div className={`${switchCount === 2?'switch-2 active':'switch-2'}`} onClick={handleSwitchAdd}>
              <p> {'>'}</p>
            </div>
          </div>
          <div className='price-carrousel'>
            <div className='prices-container'>
              <div className='price-topic'>
                <div className='price-info head'>
                  <div>
                    <p><span className='header-price'>Compare plans </span><span className='forty-off'>Get 40% Off Now</span></p>
                    <p className='price-details'>
                      {`${switchCount===0?'Choose the Help you do it - Go Agile (Agile Coaching) plan that matches your organisational needs and We will support and Coach you and your Team(s)':switchCount===1?'Choose the Help you do it - Go Agile (Agile Consultancy) plan that matches your organisational needs and We will become your Team(s)':switchCount===2?'Choose the Help you do it - Go Agile (Agile Delivery) plan that matches your organisational needs and We will become your Team(s)':'Choose the Help you do it - Go Agile (Agile Delivery) plan that matches your organisational needs and We will support and Coach you and your Team(s)'}`}
                    </p> 
                  </div>                
                </div>
                <div className='price-info'>
                  <div>
                    <p className='price-data11'>Price range</p>
                  </div>
                </div>
                <div className='price-info'>
                  <div>
                    <p className='price-data shift'>Outsourced Agile Team @XAS</p>
                  </div>
                </div>
                <div className='price-info'>
                  <div>
                    {switchCount === 0 ? <p className='price-data'>Understanding your Agile Organisation (Culture, product or service, Teams, technology) mentoring</p> : switchCount === 1 ? <p className='price-data'>Mix of Junior, Mid and Senior level Experts or seniors only</p> : switchCount === 2 ? <p className='price-data'>Mix of Junior, Mid and Senior level cross functional Experts</p> : <> </>} 
                  </div>
                </div>
                <div className='price-info'>
                  <div>
                    {switchCount === 0 ? <p className='price-data'>Understanding your Agile Leadership Role mentoring</p> : switchCount === 1 ? <p className='price-data'>Cross-Functional Expertise</p> : switchCount === 2 ? <p className='price-data'>Adoption of Agile, lean, DevOps practices suitable</p> : <></>}
                  </div>
                </div>
                <div className='price-info'>
                  <div>
                      {switchCount === 0 ? <p className='price-data shift1'>Understanding your Agile Team Member Role mentoring</p> : switchCount === 1 ? <p className='price-data shift1'>Continuous collaboration with you</p> : switchCount === 2 ? <p className='price-data shift1'>Knowledge sharing, transfer and up-skilling of internal Team</p> : <></>}
                  </div>
                </div>
  
              <div className='price-info'>
                <div>
                    {switchCount === 0 ? <p className='price-data'>2-4 Weekly Sprints, what suit our needs</p> : switchCount === 1 ? <p className='price-data'>2-4 Weekly Sprints, whatever suit our needs </p> : switchCount === 2 ? <p className='price-data'>2-4 Weekly Sprints, whatever suit our needs </p> : <></>}
                </div>
              </div>
              <div className='price-info'>
                  <div>
                    {switchCount === 0 ? <p className='price-data shift2'>Agile Assessments and Health Checks</p> : switchCount === 1 ? <p className='price-data shift2'>Agile Assessments and Continuous improvement Plan</p> : switchCount === 2 ? <p className='price-data shift2'>Agile Assessments and Continuous improvement Plan</p> : <></>}
                  </div>
              </div>
              <div className='price-info'>
                  <div>
                  {switchCount === 0 ? <p className='price-data shift4'>Agile transformation strategy & metrics definition</p> : switchCount === 1 ? <p className='price-data shift4'>Self-learning, organising, and managing Experts</p> : switchCount ===  2 ? <p className='price-data shift4'>Self-learning, organising, and managing Experts</p> : <></>}
                </div>
              </div>
              <div className='price-info'>
                <div>
                    {switchCount === 0 ? <p className='price-data shift3'>Agile Tools & Templates, Continuous Improvement Plan</p> : switchCount === 1 ? <p className='price-data shift3'>Agile Practices experience and experts with Agile Mindset</p> : switchCount === 2 ? <p className='price-data shift3'>Agile ways of working experience</p> : <></>}                 
                </div>
              </div>
              <div className='price-info'>
                <div>
                    {switchCount === 0 ? <p className='price-data shift3'>  Fun Transformation experience</p> : switchCount === 1 ? <p className='price-data shift3'>Fun Outsourcing experience</p> : switchCount === 2 ? <p className='price-data shift3'>Fun Outsourcing experience</p> : <></>}
                </div>
              </div>
              <div className='price-info'>
                <div>
                    { switchCount === 0 ? <p className='price-data shift3'> 1-Many or 1-2-1 coaching, Inspect & Adapt sessions </p> : switchCount === 1 ? <p className='price-data shift3'>Agile UX design, Development, Marketing, API and or DevOps expertise</p> : switchCount === 2 ? <p className='price-data shift3'>Agile UX design, Development and or Marketing expertise </p> : <></> }
                </div>
              </div>
              
            </div>
            
            <div className='price-topic2' onClick={clickableDiv1}>
              <div className='price-info head'>
                <div className={switchCount===0?"except":"except-2"}>
                {switchCount === 0 ? <p className='repair'>Repair</p> :switchCount=== 1? <p><span className='start'>Optimize</span></p>:switchCount===2?<p><span className='start'>Start</span></p>: <p className='repair'>Repair</p>}

                    <div className='choose-plan-btn'>
                      <div ref={ref} className='choosenPlan'> {switchCount===0 ? <p>Repair Target key improvement areas in 12 weeks</p>: switchCount=== 1? <p>Improve team persormance in 12 weeks</p>:switchCount===2?<p>Start to deliver faster in 16 weeks</p>: <p></p>}</div>               
                    </div>
                </div>
                  
                </div>
                <div className='price-info'>
                  <div>
                    {switchCount===0 ? <p className='price-data'>£1000 -- 250,000+</p> : switchCount ===1 ? <p className='price-data'>£1000 -- 250,000+</p> : switchCount ===2 ? <p className='price-data'>£10,000 -- 250,000+</p> : <></>}
                  </div>
                </div>
                <div className='price-info'>
                <div>
                  {switchCount === 0 ? <p className='price-data'>1-3 Agile Coaches</p> : switchCount === 1 ?  <p className='price-data'>1-3 Agile Freelancers</p> : switchCount === 2 ?  <p className='price-data'>1-3 Agile Team</p> : <></>}
                  </div>
                </div>
                <div className='price-info'>
                  <div>
                  {switchCount=== 1? <img src={illus} alt="" />:switchCount===2?<img src={illus} alt="" />: <p></p>}
                  </div>
                </div>
                <div className='price-info'>
                <div>
                {switchCount===2?<img src={illus} alt="" />: <p></p>}
                  </div>
                </div>
                <div className='price-info'>
                {switchCount=== 1? <img src={illus} alt="" />:switchCount===2?<img src={illus} alt="" />: <p></p>}
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                
            </div>

            <div className='price-topic3' onClick={clickableDiv2}>
            <div className='price-info head'>
              <div className={switchCount===0?" ":"except-2"}>
              {switchCount === 0 ? <p><span className='start'>Jump-Start</span> <span className='weeks'></span></p> :switchCount=== 1? <p><span className='start'>Grow</span></p>:switchCount===2?<p><span className='start'>Grow</span> </p>:<p><span className='start'>Jump-Start</span> <span className='weeks'>/4 Weeks</span></p>}
                
                <div className='choose-plan-btn'>
                  <div className='choosenPlan2'>
                    {switchCount === 0 ? <p>Start to Level up  your ways of working  in 16 weeks</p> : switchCount === 1 ? <p>Increase your Team(s) capacity in 16 weeks </p> : switchCount === 2 ? <p>Build business capacity in 24 weeks</p> : <></>}
                    </div>
                </div>
              </div>
                
                </div>
                <div className='price-info'>
                <div>
                  {switchCount === 0 ?<p className='price-data centered'>£3500 -- 350,000+</p> : switchCount ===1 ? <p className='price-data centered'>£3500 -- 350,000+</p> : switchCount === 2 ? <p  className='price-data centered'>£15,000 -- 350,000+</p> : <></>}
                  </div>
                </div>
                <div className='price-info'>
                      <div>
                      {switchCount === 0 ? <p className='price-data'>1-5 Agile Coaches</p> : switchCount === 1 ?  <p className='price-data'>1-5 Agile Freelancers</p> : switchCount === 2 ?  <p className='price-data'>3-5 Agile Team</p> : <></>}
                      </div>
                </div>
                <div className='price-info'>
                <div>
                {switchCount=== 1? <img src={illus} alt="" />:switchCount===2?<img src={illus} alt="" />: <p></p>}
                  </div>
                </div>
                <div className='price-info'>
                <div>
                {switchCount=== 1? <img src={illus} alt="" />:switchCount===2?<img src={illus} alt="" />: <p></p>}
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <img src={illus} alt="" />
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                

            </div>

            <div className='price-topic4' onClick={clickableDiv3}>
              <div className='price-info head'>
                <div className='except-2'>
                {switchCount === 0 ? <p><span className='start'>Pivot</span> <span className='weeks'></span></p> :switchCount=== 1? <p><span className='start'>Innovate</span></p>:switchCount===2?<p><span className='start'>Scale</span></p>:<p><span className='start'>$40</span> <span className='weeks'>/Month</span></p>}
                    <div className='choose-plan-btn'>
                      <div className='choosenPlan3'>
                        {switchCount === 0 ? <p>Take ways of working  to the next level  in 24 weeks </p> : switchCount === 1 ? <p>Innovate products or services in 24 hours</p> : switchCount === 2 ? <p>Advance business capacity in 32 weeks</p> : <></>}
                      </div>
                  </div>
                </div>
              </div>
                <div className='price-info'>
                <div className=''>
                      {switchCount === 0 ? <p className='price-data centered'>£10,000-- 500,000+</p> : switchCount === 1 ? <p  className='price-data centered'>£10,000 -- 500,000+</p> : switchCount === 2 ? <p  className='price-data centered'>£20,000 -- 500,000+</p> : <></>}
                  </div> 
                </div>
                <div className='price-info'>
                <div className=''>
                {switchCount === 0 ? <p className='price-data'>3-10+ Agile Coaches</p> : switchCount === 1 ?  <p className='price-data'>3-10+ Agile Freelancers</p> : switchCount === 2 ?  <p className='price-data'>5-10+ Agile Team</p> : <></>}
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>
                <div className='price-info'>
                <div>
                    <img src={illus} alt="" />
                  </div>
                </div>

            </div>
          </div>
          <div className='price-footer-note'>
                {
                  switchCount === 0 ?
                  <p>
                    Improve and Advance your organisation, leaders and Team (s) for maximum Growth with our Sankofa Agile Coaches.
                  </p> :
                   switchCount === 1 ?
                   <p>
                      Implement solutions that your customers actually enjoy Faster with our Black Panther Freelancers
                   </p> :
                  switchCount === 2 ?
                  <p>
                      Build or Scale your business to deliver products, services or projects Faster with our Marvel Teams
                  </p> :
                   <p></p>
                }
              </div>
          </div>
          <img
          className="ring-agile"
          src="../../assets/images/ring.svg"
          alt="ring"
        />
        </div>
      </section>





      <section className="wga-content-wrapper">
        <div className="wga-container">
          <div className="wga-content-left">
            <div>
              <img src={GoAgileSecond} alt="A meaningful description"/>
            </div>
          </div>

          <img
          className="ring3"
          src={ring}
          alt="ring"
        />

<img
          className="bean"
          src={bean}
          alt="ring"
        />
        
          <div className="wga-content-right">
            <div className="section-heading">
              <div>
                <strong>Three Ways to Go Agile</strong>
                <span className="underline"></span>
              </div>
            </div>
            <div className="gagile-description-para">
              <img src={check} alt="check on text" className='image-check'/>
              <p>
                We help you do it by supporting and Coaching you, your leaders and your Team(s) through our Agile coaching solutions.
              {" "}
              </p>
              <img src={check} alt="check on text" className='image-check2'/>
              <p>
                We do it for you by contracting us to serve as your outsourced remote Agile Resource through our Agile Consultancy solutions.
              </p>
              <img src={check} alt="check on text" className='image-check3'/>
              <p>
                We do it with you by taking on your outsourced projects to deliver together through our Agile Delivery solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="re-content-wrapper">
        <div className="re-container gac">
          <div className="section-heading">
            <div className="heading-box">
              <strong>Some of the Sectors</strong>
              <br />
              <span className="serve-agile">We Can Serve</span>
            </div>
            <span className="underline-go-agile"></span>
          </div>

          <div className="section-content">
            <div className="carousel-container-agile">
              <div className="card-agile">
                <div className="card-content-agile">
                  <div className='sect-desc'>
                    <img src={check} alt="" className='we-agile'/>
                    <p>EDUCATION</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile1'/>
                    <p>LEGAL</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile2'/>
                    <p>MARKETING & SALES</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile3'/>
                    <p>MANUFACTURING</p>
                  </div>
                </div>
              </div>
              <div className="card-agile">
                <div className="card-content-agile">
                <div className='sect-desc'>
                    <img src={check} alt="" className='we-agileA'/>
                    <p>HUMAN RESOURCE</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile1B'/>
                    <p>SOFTWARE</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile2B'/>
                    <p>REAL ESTATE</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile3B'/>
                    <p>NON PROFIT</p>
                  </div>
                 
                </div>
              </div>
              <div className="card-agile">
              <div className="card-content-agile">
                <div className='sect-desc'>
                    <img src={check} alt="" className='we-agileAB'/>
                    <p>FINANCE & BANKING</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile1BB'/>
                    <p>AGRICULTURE</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile2BB'/>
                    <p>HEALTHCARE</p>
                  </div>
                  <div className='sect-desc'>
                  <img src={check} alt="" className='we-agile3BB'/>
                    <p>GOVERNMENT</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="ring-img"
          src={ring2}
          alt="ring"
        />
      </section>
      <section className="xas-content-wrapper">
        <div className="xas-container">
          <div className="xas-content-left">
            <div className="section-heading">
              <div>
                <strong>Agile in IT & Non-IT Implementation</strong>
                <span className="underline"></span>
              </div>
            </div>
            <div className="goagile-description-para">
              <p>
                Agile is relevant to businesses of any type and scale. Whether you're a Solopreneur, 
                Startup, MSME, or large corporation, we'll support you in implementing Agile within 
                your IT or Non-IT organization, facilitating the transformation of your business and 
                shifting mindsets. <br/>
                <span className='why-agile-it'>We do this as follows</span>
              </p>
             <div className='list-ideas'>
              {/* <img src={check} alt="" className='list-img'/> */}
              <div className='num-color'><span className='numberr'>1</span></div>
              <p className='list-ideas-text'>
                We coach leadership, teams, and the entire organization to comprehend Agile organization 
                principles, leadership principles, and the Agile Team Member role etc. This holistic approach 
                enables you to repair, jump-start, or scale your business ways of working to ensure maximum value delivery.
              </p>
             </div>
             <div className='list-ideas'>
             <div className='num-color1'><span className='numberr'>2</span></div>
              <p className='list-ideas-text'>Become your invisible remote force that helps you go to the market faster with your digital product or services.</p>
             </div>
             <div className='list-ideas'>
             <div className='num-color2'><span className='numberr'>3</span></div>
              <p className='list-ideas-text'>Deliver your projects for you the agile way to help you create the best user experiences.</p>
             </div>
              
            </div>
          </div>
          <div className="ga-it-content-right">
            <img src={GoAgileLast} alt="A meaningful description"/>
          </div>
        </div>

        <img
          className="ga-it-ring4"
          src={ring}
          alt="ring"
        />

        <img
          className="ga-it-bean1"
          src={bean}
          alt="ring"
        />

      </section>
      
    </div>
  );
}

export default GoAgile;