import React, {useState} from "react";
import './formOverlay.css';
import emailjs from '@emailjs/browser';

function FormOverlay(props) {
    const [name, setName] = useState('');
    const plan = props.choosenPlan;
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessLink, setBusinessLink] = useState('');
    const [socialLink, setSocialLink] = useState('');
    const [businessSize, setBusinesSize] = useState('');
    const [income, setIncome] = useState('');
    const [budget, setBudget] = useState('');
    const [aboutBusiness, setAboutBusiness] = useState('');
    const [team, setAboutTeam] = useState('');
    const [aboutProject, setAboutProject] = useState('');
    const [abouChallenge, setAboutChallenge] = useState('');
    const [timeLine, setTimeline] = useState('');
    const [sendSocialStatus, setSendSocialStatus] = useState(false);
    const [btn_text, setBtnText] = useState("Submit Request a Quote Now");

    const sendRequestNow = (e) => {
        e.preventDefault()
        const templateParams = {

            fullName: name,
            plan: plan,
            email: email,
            businessName: businessName,
            socialsLink: socialLink,
            businessLink: businessLink,
            aboutProject: aboutProject,
            aboutChallenge: abouChallenge,
            projectTeam: team,
            income: income,
            budget: budget,
            businessSize: businessSize,
            timeLine: timeLine
        };

        if (
            templateParams.fullName===''||
            templateParams.email===''||
            templateParams.businessName===''||
            templateParams.socialsLink===''||
            templateParams.businessLink===''||
            templateParams.aboutProject===''||
            templateParams.aboutChallenge===''||
            templateParams.projectTeam===''||
            templateParams.income==='' ||
            templateParams.email.indexOf('@') < 0 ||
            templateParams.budget==='' ||
            templateParams.timeLine===''
            ) {
            console.log('filed empty');
            setSendSocialStatus(true)
        }else {
            setBtnText('submitting Quote.......')
            // Send email using EmailJS
            emailjs.send('service_znv0rcf', 'template_requestQuote', templateParams, 'PlhtYk9n52gllqW5d')
            .then((response) => {
                console.log('Email sent successfully:', response.status, response.text);
                props.closeForm(false);
                props.openThanks(true);
                window.scrollTo(0, 0);
            }, (error) => {
                console.error('Email sending failed:', error);
            });
        }

        
    };

    return(
        <div className={props.showForm ? "form-container display": "form-container"}>
            <div className="form-container-bg" onClick={()=>props.closeForm(false)}></div>
            <div className="myform">
                <div className="form-head">
                    <div className="fheader">
                        Request a Quote Now
                    </div>

                    <div className="bContainer">
                        <button type="button" onClick={()=>props.closeForm(false)}>X</button>
                    </div>
                </div>

                <div className="form-start">Start a Project</div>

                <div className="form-inputs">
                    <input type='text' disabled readOnly value={props.choosenPlan}/>
                    <input type='text' onChange={e => setName(e.target.value)} value={name} placeholder='Enter full name' />
                    <input type='email' onChange={e => setEmail(e.target.value)} value={email} placeholder='Enter Email' />
                    <input type='text' onChange={e => setBusinessName(e.target.value)} value={businessName} placeholder='Enter Business Name' />
                    <input type='text' onChange={e => setBusinessLink(e.target.value)} value={businessLink} placeholder='Enter Business website' />
                    <input type='text' onChange={e => setSocialLink(e.target.value)} value={socialLink} placeholder='Enter social links' />
                    <select onChange={e => setBusinesSize(e.target.value)}>
                        <option value="">Select Business Size</option>
                        <option value=" 0-3 team members"> 0-3 team members</option>
                        <option value="3-10 team members">3-10 team members</option>
                        <option value="10-25 team members">10-25 team members</option>
                        <option value="25-50 team members">25-50 team members</option>
                        <option value="50+ team members">50+ team members</option>
                    </select>
                    <select onChange={e => setIncome(e.target.value)}>
                        <option value="">Select Yearly Income</option>
                        <option value="£10,000 -- 25,000">£10,000 -- 25,000</option>
                        <option value="£25,000--50,000">£25,000--50,000</option>
                        <option value="£50,000--100,000">£50,000--100,000</option>
                        <option value="£100,000--250,000">£100,000--250,000</option>
                        <option value="£250,000--500,000">£250,000--500,000</option>
                        <option value="£500,000 -- 1,000,000">£500,000 -- 1,000,000</option>
                        <option value="£1,000,000+">£1,000,000+</option>
                    </select>
                    <input type='text' onChange={e => setBudget(e.target.value)} value={budget} placeholder='Select Business Budget' />
                    <textarea cols="30" rows="5" placeholder='Tell us about your business' onChange={e => setAboutBusiness(e.target.value)} value={aboutBusiness}></textarea>
                    <textarea cols="30" rows="5" placeholder='Tell us about your Team (s)' onChange={e => setAboutTeam(e.target.value)} value={team}></textarea>
                    <textarea placeholder='Tell us about your project/service/product need' onChange={e => setAboutProject(e.target.value)} cols="30" rows="5" value={aboutProject}></textarea>
                    <textarea placeholder='Tells us about your key challenges and next 6 months goal(s)' onChange={e => setAboutChallenge(e.target.value)} cols="30" rows="5" value={abouChallenge}></textarea>
                    <select onChange={e => setTimeline(e.target.value)}>
                        <option value="">Select Timeline</option>
                        <option value="Fridays 13:00 -- 13:15">Fridays 13:00 -- 13:15</option>
                        <option value="Fridays 13:15 -- 13:30">Fridays 13:15 -- 13:30</option>
                        <option value="Fridays 13:30 -- 13:45">Fridays 13:30 -- 13:45</option>
                        <option value="Fridays 13:45 -- 14:00">Fridays 13:45 -- 14:00</option>
                    </select>
                </div>

                <div className="errorSubmit">
                    {sendSocialStatus?
                        <p style={{ color:'red', paddingTop:'1rem'}}>Submit failed : All fields are required</p>
                    :''}
                </div>

                <div className="form-btns">
                    <button type="button" onClick={()=>props.closeForm(false)}>Go Back</button>
                    <button type="button" onClick={sendRequestNow}>{btn_text}</button>
                </div>
            </div>
        </div>
    );
}

export default FormOverlay;